const partType = {
  0: "管环",
  1: "管片",
  2: "钢筋骨架",
  3: "混凝土",
  4: "配筋",
  5: "预埋件",
  6: "直条钢筋",
  7: "盘圆钢筋",
  8: "水泥",
  9: "黄砂",
  10: "石子",
  11: "粉煤灰",
  12: "矿粉",
  13: "外加剂",
  14: "纤维",
  15: "水",
  16: "原材料",
  17: "预埋件组",
  22: "弧形件",
  27: "口字件",
};

export default partType;
