export default {
  "ProductInformation": "Product Information",
  "Concrete": "Concrete",
  "Reinforcement": "Reinforcement",
  "RebarFrame": "Rebar Frame",
  "Project": "Project",
  "ProductCategory": "Product Category",
  "ProductType": "Product Type",
  "ProductNumber": "Product Number",
  "Volume": "Volume",
  "Compressive/ImpermeabilityGrade": "Compressive / Impermeability Grade",
  "ManufacturingDate": "Manufacturing Date",
  "ConstructionEntity": "Construction Entity",
  "DesignEntity": "Design Entity",
  "Contractor": "Contractor",
  "Manufacturer": "Manufacturer",
  "SupervisionEntity": "Supervision Entity",
  "RawMaterial": "Raw Material",
  "QualityInspectionNumber": "Quality Inspection Number",
  "TestResult": "Test Result",
  "RebarSpecifications": "Rebar Specifications",
  "RebarFrameType": "Rebar Frame Type",
  "ProductionSupervisor": "Production Supervisor",
  "Inspector": "Inspector",
}