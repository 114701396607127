export default {
  "ProductInformation": "构件信息",
  "Concrete": "混凝土原材料",
  "Reinforcement": "钢筋原材料",
  "RebarFrame": "钢筋骨架信息",
  "Project": "所属项目",
  "ProductCategory": "构件类型",
  "ProductType": "型号",
  "ProductNumber": "构件编码",
  "Volume": "方量",
  "Compressive/ImpermeabilityGrade": "抗压/抗渗等级",
  "ManufacturingDate": "生产日期",
  "ConstructionEntity": "建设单位",
  "DesignEntity": "设计单位",
  "Contractor": "施工单位",
  "Manufacturer": "生产单位",
  "SupervisionEntity": "监理单位",
  "RawMaterial": "原材料",
  "QualityInspectionNumber": "质检编号",
  "TestResult": "检验结果",
  "RebarSpecifications": "钢筋规格",
  "RebarFrameType": "钢筋骨架型号",
  "ProductionSupervisor": "生产人员",
  "Inspector": "质检人员",
}