<template>
  <div>
    <div><img class="img" src="../../assets/logo-onr.png" /></div>
    <div class="con">
      <div class="conn">
        <div class="til">断料配筋信息</div>
        <div class="cot hg">
          <div class="coh hr">
            <div class="coo">所属项目:</div>
            <div class="cop">{{ allDatas.projectName }}</div>
          </div>
          <div class="coh hr">
            <div class="coq">牌号:</div>
            <div class="cor">{{ allDatas.grade }}</div>
            <div class="cos">直径:</div>
            <div class="cor">{{ allDatas.diameter }}毫米</div>
          </div>
          <div class="coh hr">
            <div class="coq">支数:</div>
            <div class="cor">{{ allDatas.quantity }}支</div>
            <div class="cos">长度:</div>
            <div class="cor">{{ allDatas.realLength }}毫米</div>
          </div>
          <div class="coh hr">
            <div class="coo">重量:</div>
            <div class="cop">{{ allDatas.weight }}公斤</div>
          </div>
          <div class="coh hr">
            <div class="coo">断料人:</div>
            <div class="cop">{{ allDatas.cutter }}</div>
          </div>
          <div class="coh">
            <div class="coo">断料时间:</div>
            <div class="cop">
              {{ allDatas.cutTime | dateTime("yyyy-MM-dd HH:mm") }}
            </div>
          </div>
        </div>
        <br />

        <div class="cot hg">
          <div class="coh hr">
            <div class="coo">质检人:</div>
            <div class="cop">{{ allDatas.inspectioner }}</div>
          </div>
          <div class="coh hr">
            <div class="coo">质检时间:</div>
            <div class="cop">
              {{ allDatas.inspectionTime | dateTime("yyyy-MM-dd HH:mm") }}
            </div>
          </div>
          <div class="coh">
            <div class="coo">质检结果:</div>
            <div class="cop">
              {{ ["待质检", "合格", "报废", "返修"][allDatas.status] }}
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$axios
      .get(
        "/api/manufacturingManagement/cut/reinforcement-info/" +
          this.$route.params.reinforcementId
      )
      .then((res) => {
        this.allDatas = res.data;
      })
      .catch((err) => {
        console.log("数据获取错误!", err);
      });
  },
  data() {
    console.log(this.$route.params.sn);
    return {
      allDatas: [],
    };
  },
};
</script>
<style scoped>
* {
  margin: 0px auto;
  padding: 0px;
}
.con {
  width: 92%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(29, 30, 30, 0.15);
  border-radius: 5px;
}
.til {
  padding: 0px 0px 10px 0px;
  line-height: 37px;
}
.title {
  padding: 23px;
  font-size: 18px;
  font-weight: bold;
  color: #f5f5f5;
  line-height: 37px;
}

.img {
  width: 100%;
  height: auto;
}
.conn {
  padding: 10px;
}
.coh {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.hg {
  border: 1px solid #417ff9;
  border-radius: 5px;
}
.hr {
  border-bottom: 1px solid rgb(190 211 253);
}
.coo {
  width: 20%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.cop {
  width: 70%;
  float: left;
  font-size: 14px;
}
.coq {
  width: 20%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.cos {
  width: 15%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.cor {
  width: 25%;
  float: left;
  font-size: 14px;
}
</style>
