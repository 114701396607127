<template>
  <div>
    <!-- 构件信息 -->
    <div v-if="allDatas" class="con">
      <div class="cot hg">
        <div class="coh hr">
          <div class="coo">名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称:</div>
          <div class="cop">
            {{ allDatas.materialName }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:</div>
          <div class="cop">
            {{ allDatas.serialNumber }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">钢筋类型:</div>
          <div class="cop">
            {{
              allDatas.type == 0
                ? "钢筋"
                : allDatas.type == 1
                ? "尾料"
                : allDatas.type == 2
                ? "废料"
                : ""
            }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">
            长&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;度:
          </div>
          <div class="cop">{{ allDatas.remainLength || 0 }}mm</div>
        </div>

        <div class="coh hr">
          <div class="coo">
            支&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数:
          </div>
          <div class="cop">{{ allDatas.count || 0 }}</div>
        </div>

        <div class="coh hr">
          <div class="coo">
            重&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量:
          </div>
          <div class="cop">{{ allDatas.weight || 0 }}kg</div>
        </div>

        <div class="coh hr">
          <div class="coo">
            厂&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;家:
          </div>
          <div class="cop">
            {{ allDatas.manufacturerName }}
          </div>
        </div>
        <div class="coh hr">
          <div class="coo">
            批&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:
          </div>
          <div class="cop">
            {{ allDatas.manufacturerBatchNumber }}
          </div>
        </div>
        <div class="coh hr">
          <div class="coo">收&nbsp;&nbsp;料&nbsp;&nbsp;人:</div>
          <div class="cop">
            {{ allDatas.stocktakingOperator }}
          </div>
        </div>
        <div class="coh hr">
          <div class="coo">收料日期:</div>
          <div class="cop">
            {{
              allDatas.inStockTime
                ? dayjs(allDatas.inStockTime).format("YYYY年MM月DD日 HH:mm:ss")
                : ""
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs"
import { RMS_URL } from "../../setting/server.seeting"
export default {
  created() {
    this.$axios({
      method: "get",
      url: `/api/rms-management/materialbatch/${this.$route.params.id}`,
      baseURL: process.env.NODE_ENV === "development" ? "/rms" : RMS_URL,
    })
      .then((res) => {
        this.allDatas = res.data
      })
      .catch((err) => {
        console.log("数据获取错误!", err)
      })
  },
  data() {
    return {
      allDatas: {},
      dayjs,
    }
  },
}
</script>
<style scoped>
.con {
  padding: 10px;
}
.coh {
  width: 100%;
  height: 40px;
  /* line-height: 40px; */
  display: flex;
  align-items: center;
}
.cok {
  height: 38px;
  background: #004387;
  border: 1px solid #417ff9;
  border-radius: 5px 5px 0px 0px;
  color: #f3f3f3;
}
.cob {
  width: 100%;
  height: 100%;
  border-left: 1px solid #417ff9;
  border-right: 1px solid #417ff9;
  border-bottom: 1px solid #417ff9;
}
.coi {
  width: 28%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.coj {
  width: 65%;
  float: left;
  font-size: 14px;
}
.coo {
  max-width: 35%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
  white-space: nowrap;
}
.cop {
  width: 65%;
  float: left;
  font-size: 14px;

  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}
.coq {
  width: 20%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.cos {
  width: 15%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.cor {
  width: 25%;
  float: left;
  font-size: 14px;
}
.hg {
  border: 1px solid #417ff9;
  border-radius: 5px;
}
.hr {
  border-bottom: 1px solid rgb(190 211 253);
}
</style>
