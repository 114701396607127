export default {
  "ProductInformation": "構件資訊",
  "Concrete": "混凝土原材料",
  "Reinforcement": "鋼筋原材料",
  "RebarFrame": "鋼筋骨架資訊",
  "Project": "所屬項目",
  "ProductCategory": "構件類型",
  "ProductType": "型號",
  "ProductNumber": "構件編碼",
  "Volume": "方量",
  "Compressive/ImpermeabilityGrade": "抗壓/抗滲等級",
  "ManufacturingDate": "生產日期",
  "ConstructionEntity": "建設單位",
  "DesignEntity": "設計單位",
  "Contractor": "施工單位",
  "Manufacturer": "生產單位",
  "SupervisionEntity": "監理單位",
  "RawMaterial": "原材料",
  "QualityInspectionNumber": "質檢編號",
  "TestResult": "檢驗結果",
  "RebarSpecifications": "鋼筋規格",
  "RebarFrameType": "鋼筋骨架型號",
  "ProductionSupervisor": "生產人員",
  "Inspector": "質檢人員",
}