<template>
  <div id="app" v-wechat-title="$route.meta.title" v-if="loading">
    <router-view></router-view>
  </div>
</template>

<script>
//import Button from 'vant/lib/button';
import { langOption, changeLang } from './plugin/i18n/index'
import 'vant/lib/button/style';
export default {
  name: 'App',
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.$axios
      .get(`/api/pes-management/mpart/projectLanguage?outSN=${this.$route.params.sn}`)
      .then(({ data }) => {
        this.loading = true
        const lang = langOption.find(x => x.key === data)?.id
        this.$i18n.locale = lang
        changeLang(lang)
        console.log("🚀 ~ .then ~ res:", lang)
      })
      .catch((err) => {
        console.log("数据获取错误!", err);
      }).finally(() => {
        this.loading = true
      });
  },
}
</script>

<style>
body {
  display: block;
  margin: 0px;
  background-color: rgb(245, 245, 245, 1);
  background: linear-gradient(rgba(0, 67, 135, 1), rgb(1, 78, 155)) no-repeat, rgb(245, 245, 245, 1);
  background-size: 100% 94px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
