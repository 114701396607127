<template>
  <div>
    <div class="header">
      <div class="title">隧道股份 上海隧道</div>
      <div class="lang">
        <select style="width: 100px;" v-model="$i18n.locale" @change="changeLang($i18n.locale)">
          <option v-for="(lang) in langOption" :key="lang.id" :value="lang.id">
            {{ lang.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="con">
      <ul>
        <li v-on:click="searId = 1" v-bind:class="{ active: searId == 1 }">
          {{ $t('ProductInformation') }}
        </li>
        <li @click="searId = 2" :class="{ active: searId == 2 }">{{ $t('Concrete') }}</li>
        <li @click="searId = 3" :class="{ active: searId == 3 }">
          {{ $t('Reinforcement') }}
        </li>
        <li @click="searId = 4" :class="{ active: searId == 4 }">
          {{ $t('RebarFrame') }}
        </li>
      </ul>
      <div id="wear" class="content">
        <div v-show="searId == 1">
          <TectonicInfo />
        </div>
        <div v-show="searId == 2">
          <MaterialInfo />
        </div>
        <div v-show="searId == 3">
          <RawMaterialInfo />
        </div>
        <div v-show="searId == 4">
          <FromcageInfo id="123" />
        </div>
      </div>
    </div>
    <br /><br />
  </div>
</template>
<script>
import TectonicInfo from "./TectonicInfo";
import MaterialInfo from "./MaterialInfo";
import RawMaterialInfo from "./RawMaterialInfo";
import FromcageInfo from "./FromcageInfo";
import { langOption, changeLang } from '../../plugin/i18n/index.js'
export default {
  name: "HomeInfo",
  components: {
    TectonicInfo,
    MaterialInfo,
    FromcageInfo,
    RawMaterialInfo
  },

  data() {
    return {
      searId: 1,
      langOption,
      changeLang
    };
  },
};
</script>

<style scoped>
* {
  margin: 0px auto;
  padding: 0px;
}

.con {
  width: 92%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(29, 30, 30, 0.15);
  border-radius: 5px;
  margin-top: -20px;
}

ul {
  display: flex;
  align-items: center;
}

li {
  padding: 10px 0;
  list-style: none;
  float: left;
  width: 25%;
  /* height: 38px; */
  /* line-height: 37px; */
  font-size: 14px;
  /* background: #eeeeee; */
  text-align: center;
  cursor: pointer;
}

.active {
  /* background: #C55040; */
  border-bottom: 3px solid #c55040;
  color: #c55040;
}

.title {
  padding: 23px;
  font-size: 18px;
  font-weight: bold;
  color: #f5f5f5;
  line-height: 37px;
  margin: 0;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lang {
  padding: 23px;
  font-size: 18px;
  font-weight: bold;
  color: #f5f5f5;
  line-height: 37px;
  margin: 0;
}

.content {
  margin-top: 10px;
}
</style>
