import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
//响应组件的动作
const actions = {

}
//用于操作数据
const mutations = {

}
//用于存储数据
const state = {

}

//创建store 并导出
export default new Vuex.Store({
    actions,
    mutations,
    state,
})
