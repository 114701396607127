<!--
 * @Author: Mr.Cong Wei
 * @Date: 2022-02-08 09:00:27
 * @LastEditTime: 2023-07-07 13:50:40
-->
<template>
  <div>
    <div class="con">
      <div class="conn">
        <div class="cot">
          <div class="cok" style="width:30%">{{ $t('RawMaterial') }}</div>
          <div class="cok" style="width:40%">{{ $t('QualityInspectionNumber') }}</div>
          <div class="cok" style="width:30%">{{ $t('TestResult') }}</div>
        </div>
        <div class="cob" v-for="(item, index) in allDatas.items" :key="index">
          <div class="coh hr">
            <div class="coi" style="width:30%">{{ item.partName }}</div>
            <div class="coi" style="width:40%">
              {{ item.qualityInspectionNumber }}
            </div>
            <div class="coi" style="width:30%">
              {{ item.qualityInspectionResult }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$axios
      .get(
        `/api/pes-management/qr-code/qrCodeMaterialInfo/${this.$route.params.sn}`
      )
      .then((res) => {
        this.allDatas = res.data;
      })
      .catch((err) => {
        console.log("数据获取错误!", err);
      });
  },
  data() {
    return {
      allDatas: [],
    };
  },
};
</script>
<style scoped>
.con {
  padding: 10px;
}

.conn {
  border-radius: 5px;
  border: 1px solid #417ff9;
}

.coh {
  width: 100%;
  height: 40px;
  line-height: 40px;
}

.hr {
  border-bottom: 1px solid rgb(190 211 253);
}

.cot {
  background: #004387;
  color: #f3f3f3;
  /* height: 38px; */
  /* line-height: 38px; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.cok {
  width: 25%;
  float: left;
  text-align: center;
  font-weight: bold;
}

.cob {
  width: 100%;
  height: 100%;
}

.coi {
  width: 25%;
  height: 100%;
  float: left;
  font-size: 13px;
  color: #666666;
  text-align: center;
}
</style>
