<template>
  <div class="ta">
    <div class="tb">
      <div class="td">
        <div class="th font20 mt5">管片信息</div>
        <div class="th">
          <div class="th-c">管片编码</div>
          <div class="th-a" v-if="$route.params.sn">{{ $route.params.sn }}</div>
        </div>
        <div class="th">
          <div class="th-c">生产单位</div>
          <div class="th-a" v-if="allDatas.manufacturer">
            {{ allDatas.manufacturer }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">项目名称</div>
          <div class="th-a" v-if="allDatas.projectName">
            {{ allDatas.projectName }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">部件名称</div>
          <div class="th-a" v-if="allDatas.partName">
            {{ allDatas.partName }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">入模骨架</div>
          <div class="th-a" v-if="allDatas.cageName">
            {{ allDatas.cageName }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">构件类型</div>
          <div class="th-a" v-if="allDatas.partType">
            {{ partType[allDatas.partType] }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">方量</div>
          <div class="th-a" v-if="allDatas.volume">{{ allDatas.volume }}</div>
        </div>
        <div class="th">
          <div class="th-c">重量</div>
          <div class="th-a" v-if="allDatas.weight">{{ allDatas.weight }}</div>
        </div>
        <div class="th">
          <div class="th-c">质检人</div>
          <div class="th-a" v-if="allDatas.inspectioner">
            {{ allDatas.inspectioner }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">质检时间</div>
          <div class="th-a" v-if="allDatas.inspectionTime">
            {{ allDatas.inspectionTime | dateTime("yyyy-MM-dd HH:mm") }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">质检结果</div>
          <div class="th-a" v-if="allDatas.status">
            {{ ["待质检", "合格", "报废", "返修"][allDatas.status] }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">隐蔽工程</div>
          <div class="th-a" v-if="allDatas.detectionOfConcealedWorkStatus">
            {{
              ["待质检", "合格", "报废", "返修"][
                allDatas.detectionOfConcealedWorkStatus
              ]
            }}
          </div>
        </div>
        <div
          v-for="(item, index) in allDatas.segmentTraceInfoDtoList"
          :key="index"
        >
          <div class="th font20 mt5">生产信息{{ index + 1 }}</div>
          <div class="th">
            <div class="th-c">生产状态</div>
            <div class="th-a" v-if="item.processCode">
              {{ processCode[item.processCode] }}
            </div>
          </div>
          <div class="th">
            <div class="th-c">生产人员</div>
            <div class="th-a" v-if="item.user">{{ item.user }}</div>
          </div>
          <div class="th">
            <div class="th-c">开始时间</div>
            <div class="th-a" v-if="item.beginAt">
              {{ item.beginAt | dateTime("yyyy-MM-dd HH:mm") }}
            </div>
          </div>
          <div class="th">
            <div class="th-c">结束时间</div>
            <div class="th-a" v-if="item.endAt">
              {{ item.endAt | dateTime("yyyy-MM-dd HH:mm") }}
            </div>
          </div>
        </div>
        <div class="th font20 mt5">养护水池</div>
        <div class="th">
          <div class="th-c">水池名称</div>
          <div class="th-a" v-if="allDatas.poolName">
            {{ allDatas.poolName }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">入池人员</div>
          <div class="th-a" v-if="allDatas.inPoolUser">
            {{ allDatas.inPoolUser }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">入池时间</div>
          <div class="th-a" v-if="allDatas.inPoolTime">
            {{ allDatas.inPoolTime | dateTime("yyyy-MM-dd HH:mm") }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">出池人员</div>
          <div class="th-a" v-if="allDatas.outPoolUser">
            {{ allDatas.outPoolUser }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">出池时间</div>
          <div class="th-a" v-if="allDatas.outPoolTime">
            {{ allDatas.outPoolTime | dateTime("yyyy-MM-dd HH:mm") }}
          </div>
        </div>
        <div class="th font20 mt5">存放堆场</div>
        <div class="th">
          <div class="th-c">堆场名称</div>
          <div class="th-a" v-if="allDatas.storageYardName">
            {{ allDatas.storageYardName }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">入场人员</div>
          <div class="th-a" v-if="allDatas.inStorageYardUser">
            {{ allDatas.inStorageYardUser }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">入场时间</div>
          <div class="th-a" v-if="allDatas.inStorageYardTime">
            {{ allDatas.inStorageYardTime | dateTime("yyyy-MM-dd HH:mm") }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">出场人员</div>
          <div class="th-a" v-if="allDatas.outStorageYardUser">
            {{ allDatas.outStorageYardUser }}
          </div>
        </div>
        <div class="th">
          <div class="th-c">出场时间</div>
          <div class="th-a" v-if="allDatas.outStorageYardTime">
            {{ allDatas.outStorageYardTime | dateTime("yyyy-MM-dd HH:mm") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partType from "../../extension/parttype";
import processCode from "../../extension/processcode";
export default {
  name: "QrcdeInfo",
  created() {
    this.$axios
      .get(
        "/api/manufacturing-management/mpart/segment-info/" +
          this.$route.params.sn
      )
      .then((res) => {
        this.allDatas = res.data;
        this.allDatas.segmentTraceInfoDtoList = this.sortBykey(
          res.data.segmentTraceInfoDtoList,
          "processCode"
        );
      })
      .catch((err) => {
        console.log("数据获取错误!", err);
      });
  },
  data() {
    return {
      allDatas: {},
      partType,
      processCode,
    };
  },
  methods: {
    sortBykey(ary, key) {
      return ary.sort(function(a, b) {
        let x = a[key];
        let y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
  },
};
</script>

<style scoped>
.mt5 {
  margin-top: 5px;
}
.font20 {
  font-size: 18px;
  font-weight: bold;
}

.ts .td .th {
  width: 100%;
  font-size: 8px;
}

.th {
  line-height: 40px;
  height: 40px;
  border-bottom: 1px dashed rgb(212, 208, 208);
}

.ta {
  display: block;
  margin: 40px 12px;
}

.tb {
  border-color: rgb(212, 208, 208);
  border-width: 1px;
  border-style: solid;
  padding: 15px;
  background-color: rgb(254, 253, 253, 1);
  /* height: 1200px; */
  border-radius: 5px;
}

.th-a {
  text-align: right;
  float: left;
  width: 75%;
}

.th-c {
  text-align: left;
  float: left;
  width: 25%;
}
</style>
