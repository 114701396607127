<template>
  <div>
    <div class="title">隧道股份 上海隧道</div>
    <div class="con">
      <div class="con_title">水养池水质信息</div>
      <div id="wear">
        <div><PoolWaterQualityInfo /></div>
      </div>
    </div>
    <br /><br />
  </div>
</template>
<script>
import PoolWaterQualityInfo from "./PoolWaterQualityInfo"

export default {
  name: "HomeInfo",
  components: {
    PoolWaterQualityInfo,
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
* {
  margin: 0px auto;
  padding: 0px;
}
.con {
  width: 92%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(29, 30, 30, 0.15);
  border-radius: 5px;
  margin-top: -20px;
}
.con_title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
}
li {
  list-style: none;
  float: left;
  width: 25%;
  height: 38px;
  line-height: 37px;
  font-size: 14px;
  /* background: #eeeeee; */
  text-align: center;
  cursor: pointer;
}

.active {
  /* background: #C55040; */
  border-bottom: 3px solid #c55040;
  color: #c55040;
}
.title {
  padding: 23px;
  font-size: 18px;
  font-weight: bold;
  color: #f5f5f5;
  line-height: 37px;
}
</style>
