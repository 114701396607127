<template>
  <div>
    <div class="con">
      <div class="cot hg">
        <div class="coh hr">
          <div class="coo">{{ $t('Project') }}:</div>
          <div class="cop">{{ allDatas.projectName }}</div>
        </div>
        <div class="coh hr">
          <div class="coo">{{ $t('RebarFrameType') }}:</div>
          <div class="cop">{{ allDatas.name }}</div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('ManufacturingDate') }}:</div>
          <div class="cop">
            {{
              allDatas.operationTime &&
              dayjs(allDatas.operationTime).format("YYYY-MM-DD")
            }}
          </div>
        </div>
        <div class="coh hr">
          <div class="coo">{{ $t('ProductionSupervisor') }}:</div>
          <div class="cop">{{ allDatas.userName }}</div>
        </div>
        <div class="coh">
          <div class="coo">{{ $t('Inspector') }}:</div>
          <div class="cop">{{ allDatas.qualityInspectors }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    id: String,
  },
  created() {
    this.$axios
      .get(
        `/api/pes-management/qr-code/qrCodeReinforcementCage/${this.$route.params.sn}`
      )
      .then((res) => {
        this.allDatas = res.data;
      })
      .catch((err) => {
        console.log("数据获取错误!", err);
      });
  },
  data() {
    console.log(this.id);
    return {
      allDatas: {},
      dayjs,
    };
  },
};
</script>
<style scoped>
.con {
  padding: 10px;
}

.coh {
  min-height: 40px;
  width: 100%;
  /* height: 40px; */
  /* line-height: 40px; */
  display: flex;
  align-items: center;
}

.hg {
  border: 1px solid #417ff9;
  border-radius: 5px;
}

.hr {
  border-bottom: 1px solid rgb(190 211 253);
}

.coo {
  width: 30%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 5px 10px;
}

.cop {
  width: 60%;
  float: left;
  font-size: 14px;
}
</style>
