<!--
 * @Author: Mr.Cong Wei
 * @Date: 2022-02-08 09:00:27
 * @LastEditTime: 2023-07-07 13:50:40
-->
<template>
  <div>
    <div class="con">
      <div class="conn">
        <div class="cot">
          <!-- <div class="cok" style="width:10%">序号</div> -->
          <div class="cok" style="width:40%">水养池名称</div>
          <div class="cok" style="width:40%">水养池信息</div>
          <div class="cok" style="width:20%">操作</div>
        </div>
        <div class="cob" v-for="(item, index) in allDatas" :key="index">
          <div class="coh hr">
            <!-- <div class="coi" style="width:10%">{{ item.index }}</div> -->
            <div class="coi" style="width:40%;">
              {{ item.name }}
            </div>
            <div class="coi" style="width:40%;line-height: 10px !important;">
              <div>
                <p>温度 : {{ item.temperature }}℃</p>
                <p>PH : {{ item.potentialHydrogen }}</p>
                <p>
                  模式 :
                  <span
                    :style="item.isDisablePoolUpdate == true ? 'color:red' : ''"
                  >
                    {{
                      item.isDisablePoolUpdate == false
                        ? "采集显示"
                        : item.isDisablePoolUpdate == true
                        ? "手动设置"
                        : ""
                    }}
                  </span>
                </p>
              </div>
            </div>
            <div class="coi" style="width:20%">
              <span
                style="padding:5px 15px;border: 1px solid black;cursor: pointer;background-color: #004387;color:white"
                @click="onToModal(item.id)"
              >
                编辑
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      style="width:280px;height: 350px;position: absolute;left: 37%;top: 20%;margin-left: -100px;z-index: 100001;background: #ffffff;padding: 10px;border-radius: 8px;"
    >
      <div style="text-align: center;font-weight: 700;margin-bottom: 20px;">
        水养池详情
      </div>
      <div style="line-height: 45px;">
        <div>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名称 : </span>
          <span> {{ name }}</span>
        </div>
        <div>
          显示模式 :
          <span>
            <input
              type="radio"
              name="type"
              value="1"
              checked
              @click="changeCheck"
            />采集显示
            <input
              type="radio"
              name="type"
              value="2"
              @click="changeCheck"
            />手动配置
          </span>
        </div>
        <div>
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P H :
          </span>
          &nbsp;<input
            type="text"
            style="width: 100px;height: 20px;"
            placeholder="请输入PH值"
            v-model="potentialHydrogen"
          />
        </div>
        <div>
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;温度 :
          </span>
          &nbsp;<input
            type="text"
            style="width: 100px;height: 20px;"
            placeholder="请输入温度"
            v-model="temperature"
          />
          <span>&nbsp;℃</span>
          <div
            v-if="isDisablePoolUpdate == false"
            style="color: red;font-size: 14px;"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;*注 : PH值和温度仅手动配置模式下生效
          </div>
        </div>
      </div>

      <div
        style="display: flex;justify-content: space-around;margin-top: 30px;"
      >
        <span
          style="padding:5px 15px;border: 1px solid black;cursor: pointer;background-color: #004387;color:white"
          @click="onToUpdate"
        >
          保存
        </span>
        <span
          style="padding:5px 15px;border: 1px solid black;cursor: pointer;background-color: #004387;color:white"
          @click="showModal = false"
        >
          取消
        </span>
      </div>
    </div>

    <div
      v-if="showModal"
      class="overLay"
      id="overLay"
      @click="showModal = false"
      style="position: fixed;width: 100%;height: 100%;top: 0;left: 0;opacity: 0.5;background-color: #000000;z-index: 100000;"
    ></div>
  </div>
</template>
<script>
import { LOCAL_URL } from "../../setting/server.seeting"
export default {
  created() {
    this.getDataList()
  },
  methods: {
    // [Get]获取水养池列表：http://localhost:8002/api/app/pool/pool-list
    // [Get]获取水养池详情：http://localhost:8002/api/app/pool/{id}/by-id/
    // [Update]更新水养池：http://localhost:8002/api/app/pool/pool
    getDataList() {
      this.$axios({
        method: "get",
        url: `/api/app/pool/pool-list`,
        baseURL: LOCAL_URL,
      })
        .then((res) => {
          this.allDatas = res.data
        })
        .catch((err) => {
          console.log("数据获取错误!", err)
        })
    },
    changeCheck(e) {
      console.log("e", e)
      console.log("e", e.target.defaultValue)
      this.isDisablePoolUpdate = e.target.defaultValue == 1 ? false : true
    },

    onToModal(id) {
      console.log("id", id)
      this.showModal = true
      this.$axios({
        method: "get",
        url: `/api/app/pool/${id}/by-id/`,
        baseURL: LOCAL_URL,
      })
        .then(({ data }) => {
          console.log("数据", data)
          this.isDisablePoolUpdate = data.isDisablePoolUpdate
          this.potentialHydrogen = data.potentialHydrogen
          this.temperature = data.temperature
          this.name = data.name
          this.id = data.id
          this.poolId = data.poolId
          this.code = data.code
          this.productionBaseId = data.productionBaseId
          this.description = data.description
          this.comPortName = data.comPortName
        })
        .catch((err) => {
          console.log("数据获取错误!", err)
        })
    },

    onToUpdate() {
      this.showModal = false
      this.$axios({
        method: "put",
        url: `/api/app/pool/pool`,
        data: {
          id: this.id,
          name: this.name,
          potentialHydrogen: this.potentialHydrogen,
          temperature: this.temperature,
          isDisablePoolUpdate: this.isDisablePoolUpdate,
          poolId: this.poolId,
          code: this.code,
          productionBaseId: this.productionBaseId,
          description: this.description,
          comPortName: this.comPortName,
        },
        baseURL: LOCAL_URL,
      })
        .then((res) => {
          console.log("提交结果", res)
          // this.allDatas = res.data
          this.getDataList()
        })
        .catch((err) => {
          console.log("数据保存失败!", err)
        })
    },
  },
  data() {
    return {
      allDatas: [],
      showModal: false,
      id: undefined,
      name: undefined,
      potentialHydrogen: 0,
      temperature: 0,
      isDisablePoolUpdate: false,

      poolId: undefined,
      code: undefined,
      productionBaseId: undefined,
      description: undefined,
      comPortName: undefined,
    }
  },
}
</script>
<style scoped>
.con {
  padding: 10px;
}
.conn {
  border-radius: 5px;
  border: 1px solid #417ff9;
}
.coh {
  width: 100%;
  height: 80px;
  line-height: 80px;
}

.hr {
  border-bottom: 1px solid rgb(190 211 253);
}

.cot {
  background: #004387;
  color: #f3f3f3;
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
}
.cok {
  width: 25%;
  float: left;
  text-align: center;
  font-weight: bold;
}
.cob {
  width: 100%;
  height: 100%;
}
.coi {
  width: 25%;
  height: 100%;
  float: left;
  font-size: 13px;
  color: #666666;
  text-align: center;
  align-items: center;
}
</style>
