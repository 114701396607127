const processCode = {
  0: "钢膜清洗",
  1: "涂脱模剂",
  2: "入模匹配",
  3: "放置预埋件",
  4: "隐蔽工程检测",
  5: "浇筑振捣",
  6: "收水抹面",
  7: "蒸养",
  8: "脱模",
  9: "水养",
};

export default processCode;
