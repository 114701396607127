<template>
  <div>
    <div class="con">
      <div class="cot">
        <div class="coh cok">&nbsp;&nbsp;发货请求信息</div>
        <div class="cob">
          <div class="coh">
            <div class="coi">发货人:</div>
            <div class="coj">
              {{ allDatas.operatorName }}
            </div>
          </div>
          <div class="coh">
            <div class="coi">发货时间:</div>
            <div class="coj">
              {{
                allDatas.deliverAt &&
                  dayjs(allDatas.deliverAt).format("YYYY-MM-DD")
              }}
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="cot">
        <div class="coh cok">&nbsp;&nbsp;发货信息</div>
        <div class="cob">
          <div class="coh">
            <div class="coi">发运标段:</div>
            <div class="coj">
              {{ allDatas.deliverTender }}
            </div>
          </div>
          <div class="coh">
            <div class="coi">合格证编号:</div>
            <div class="coj">
              {{ allDatas.certificateNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "DeliveryInfo",
  created() {
    this.$axios
      .get(
        `/api/pes-management/qr-code/qrCodeDeliverInfo/${this.$route.params.sn}`
      )
      .then((res) => {
        this.allDatas = res.data;
      })
      .catch((err) => {
        console.log("数据获取错误!", err);
      });
  },
  data() {
    return {
      allDatas: {},
      dayjs,
    };
  },
};
</script>
<style scoped>
.con {
  padding: 10px;
}
.coh {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.cok {
  height: 38px;
  background: #004387;
  border: 1px solid #417ff9;
  border-radius: 5px 5px 0px 0px;
  color: #f3f3f3;
}
.cob {
  width: 100%;
  height: 100%;
  border-left: 1px solid #417ff9;
  border-right: 1px solid #417ff9;
  border-bottom: 1px solid #417ff9;
}
.coi {
  width: 28%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.coj {
  width: 65%;
  float: left;
  font-size: 14px;
}
</style>
