import Vue from 'vue';

Vue.filter('dateTime', function (date, type) {
    if (date != null && date != "") {
        let yer,
            month,
            day,
            HH,
            mm,
            ss;
        let time = new Date(date),
            timeDate;
        yer = time.getFullYear();
        month = time.getMonth() + 1;
        day = time.getDate();
        HH = time.getHours(); //获取系统时，
        mm = time.getMinutes(); //分
        ss = time.getSeconds(); //秒

        month = (month < 10) ? ('0' + month) : month;
        day = (day < 10) ? ('0' + day) : day;
        HH = (HH < 10) ? ('0' + HH) : HH;
        mm = (mm < 10) ? ('0' + mm) : mm;
        ss = (ss < 10) ? ('0' + ss) : ss;

        switch (type) {
            case 'yyyy':
                timeDate = String(yer);
                break;
            case 'yyyy-MM':
                timeDate = yer + '年' + month;
                break;
            case 'yyyy-MM-dd':
                timeDate = yer + '年' + month + '月' + day + '日';
                break;
            case 'yyyy/MM/dd':
                timeDate = yer + '/' + month + '/' + day;
                break;
            case 'yyyy-MM-dd HH:mm:ss':
                timeDate = yer + '年' + month + '月' + day + '日' + HH + ':' + mm + ':' + ss;
                break;
            case 'HH:mm:ss':
                timeDate = HH + ':' + mm + ':' + ss;
                break;
            case 'mm:ss':
                timeDate = mm + '分' + ss + '秒';
                break;    
            case 'yyyy-MM-dd HH:mm':
                timeDate = yer + '年' + month + '月' + day + '日' + HH + ':' + mm;
                break;    
            case 'MM':
                timeDate = String(month);
                break;
            default:
                timeDate = yer + '年' + month + '月' + day + '日';
                break;
        }
        return timeDate
    } else {
        return ''
    }
})