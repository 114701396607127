import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from './locales/en';
import zhHans from './locales/zhHans';
import zhHant from './locales/zhHant';

Vue.use(VueI18n);
let lang = localStorage.getItem('lang');
if (!lang) {
  localStorage.setItem('lang', 'zhHans');
  lang = 'zhHans';
}

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'zhHans',
  messages: {
    en,
    zhHans,
    zhHant
  }
});



export default i18n

export const langOption = [
  { id: 'zhHans', name: '简体中文', key: 0 },
  { id: 'zhHant', name: '繁體中文', key: 2 },
  { id: 'en', name: 'English', key: 4 },
]

export const changeLang = (lang) => localStorage.setItem('lang', lang);


