/*
 * @Author: Mr.Cong Wei
 * @Date: 2023-07-07 10:29:21
 * @LastEditTime: 2023-07-07 13:50:50
 */
import Vue from "vue"
import App from "./App.vue"
import axios from "axios"
import qs from "qs"
import jq from "jquery"
import VueRouter from "vue-router"
import router from "./router"
import i18n from "./plugin/i18n/index"
import Vuex from "vuex"
import store from "./store"
import Moment from "moment"
import VueWechatTitle from "vue-wechat-title"
import "./pipe-vue.js"
//import "tailwindcss/tailwind.css"
import { Button, Cell, CellGroup } from "vant"

import { PES_URL, LOCAL_URL } from "./setting/server.seeting"
axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? "/pes" : PES_URL
console.log(process.env)
// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    console.log("---------------------")

    console.log(config)
    console.log(config.url)
    console.log("---------------------")
    console.log(process.env.NODE_ENV)
    switch (config.baseURL) {
      case LOCAL_URL:
        axios.defaults.baseURL =
          process.env.NODE_ENV === "development" ? "/bendi"
            : "http://localhost:8002/"
        break
      default:
        axios.defaults.baseURL =
          process.env.NODE_ENV === "development" ? "/pes" : PES_URL
        break
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

Vue.filter("formatDate", function (value) {
  return Moment(value).format("MM:ss")
})

//Vue.use(Vant);
Vue.use(VueWechatTitle)
Vue.prototype.$moment = Moment
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.prototype.$jq = jq
Vue.config.productionTip = false
Vue.use(Button)
  .use(Cell)
  .use(CellGroup)

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.tatle
  }
  next()
})

new Vue({
  render: (h) => h(App),
  store,
  i18n,
  router: router,
}).$mount("#app")
