<template>
  <div>
    <!-- 构件信息 -->
    <div v-if="allDatas" class="con">
      <div class="cot hg">
        <div class="coh hr">
          <div class="coo">{{ $t('Project') }}:</div>
          <div class="cop">
            {{ allDatas.projectName }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('ProductCategory') }}:</div>
          <div class="cop">
            {{ allDatas.partTypeName }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('ProductType') }}:</div>
          <div class="cop">
            {{ allDatas.name }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('ProductNumber') }}:</div>
          <div class="cop">
            {{ allDatas.outerSerialNumber }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('Volume') }}:</div>
          <div class="cop">{{ allDatas.volume || 0 }}m³</div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('Compressive/ImpermeabilityGrade') }}:</div>
          <div class="cop">
            {{ allDatas.concreteGrade }}/{{ allDatas.impermeabilityGrade }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('ManufacturingDate') }}:</div>
          <div class="cop">
            {{
              allDatas.productionTime &&
              dayjs(allDatas.productionTime).format("YYYY-MM-DD")
            }}
          </div>
        </div>



        <div class="coh hr">
          <div class="coo">{{ $t('ConstructionEntity') }}:</div>
          <div class="cop">
            {{ allDatas.proprietorName }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('DesignEntity') }}:</div>
          <div class="cop">
            {{ allDatas.designUnit }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('Contractor') }}:</div>
          <div class="cop">
            {{ allDatas.constrctionContractorUnit }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('Manufacturer') }}:</div>
          <div class="cop">
            {{ allDatas.constrctionEmployerUnit }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">{{ $t('SupervisionEntity') }}:</div>
          <div class="cop">
            {{ allDatas.supervisorName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  created() {
    this.$axios
      .get(
        `/api/pes-management/qr-code/qrCodeMPartInfo/${this.$route.params.sn}`
      )
      .then((res) => {
        this.allDatas = res.data;
      })
      .catch((err) => {
        console.log("数据获取错误!", err);
      });
  },
  data() {
    return {
      allDatas: {},
      dayjs,
    };
  },
};
</script>
<style scoped>
.con {
  padding: 10px;
}

.coh {
  width: 100%;
  min-height: 40px;
  /* line-height: 40px; */
  display: flex;
  align-items: center;
}

.cok {
  height: 38px;
  background: #004387;
  border: 1px solid #417ff9;
  border-radius: 5px 5px 0px 0px;
  color: #f3f3f3;
}

.cob {
  width: 100%;
  height: 100%;
  border-left: 1px solid #417ff9;
  border-right: 1px solid #417ff9;
  border-bottom: 1px solid #417ff9;
}

.coi {
  width: 28%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 5px 10px;
}

.coj {
  width: 65%;
  float: left;
  font-size: 14px;
}

.coo {
  width: 30%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 5px 10px;
}

.cop {
  width: 60%;
  float: left;
  font-size: 14px;

  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.coq {
  width: 20%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 5px 10px;
}

.cos {
  width: 15%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 5px 10px;
}

.cor {
  width: 25%;
  float: left;
  font-size: 14px;
}

.hg {
  border: 1px solid #417ff9;
  border-radius: 5px;
}

.hr {
  border-bottom: 1px solid rgb(190 211 253);
}
</style>
