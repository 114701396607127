import VueRouter from "vue-router"
import {
  SegmentHomeInfo,
  SegmentTectonicInfo,
  SegmentMaterialInfo,
  SegmentFromcageInfo,
  SegmentDeliveryInfo,
} from "../components/segment/index"
import {
  MaterialHomeInfo,
  MaterialMaterialsInfo,
} from "../components/material/index"
import {
  ReinforcementHomeInfo,
  ReinforcementInfo,
} from "../components/reinforcement/index"
import { CageHomeInfo, CageInfo } from "../components/cage/index"

import { PoolHomeInfo, PoolWaterQualityInfo } from "../components/pool/index"

export default new VueRouter({
  routes: [
    {
      path: "/segment/:sn", // SmPr0320210731003
      component: SegmentHomeInfo,
      meta: {
        title: "构件信息",
      },
      children: [
        {
          path: "/tectonic",
          component: SegmentTectonicInfo,
          meta: {
            title: "构件信息",
          },
        },
        {
          path: "/segment-material",
          component: SegmentMaterialInfo,
          meta: {
            title: "原材料信息",
          },
        },
        {
          path: "/fromcage",
          component: SegmentFromcageInfo,
          meta: {
            title: "钢筋骨架信息",
          },
        },
        {
          path: "/delivery",
          component: SegmentDeliveryInfo,
          meta: {
            title: "管片出厂信息",
          },
        },
      ],
    },
    {
      path: "/material/:id", // 3a0e4e61-2ce5-6a32-5f09-12f34d336ee6
      component: MaterialHomeInfo,
      meta: {
        title: "材料实物信息",
      },
      children: [
        {
          path: "material-material",
          component: MaterialMaterialsInfo,
          meta: {
            title: "材料实物信息",
          },
        },
      ],
    },
    {
      path: "/reinforcement/:id", // 3a0e4e61-2ce5-6a32-5f09-12f34d336ee6
      component: ReinforcementHomeInfo,
      meta: {
        title: "半成品钢筋信息",
      },
      children: [
        {
          path: "reinforcement-info",
          component: ReinforcementInfo,
          meta: {
            title: "半成品钢筋信息",
          },
        },
      ],
    },
    {
      path: "/cage/:id", // 3a0e4e61-2ce5-6a32-5f09-12f34d336ee6
      component: CageHomeInfo,
      meta: {
        title: "钢筋骨架信息",
      },
      children: [
        {
          path: "cage-info",
          component: CageInfo,
          meta: {
            title: "钢筋骨架信息",
          },
        },
      ],
    },
    {
      path: "/pool",
      component: PoolHomeInfo,
      meta: {
        title: "水养池水质信息",
      },
      children: [
        {
          path: "poolWaterQuality-info",
          component: PoolWaterQualityInfo,
          meta: {
            title: "钢筋骨架信息",
          },
        },
      ],
    },
  ],
})
