/*
 * @Author: Mr.Cong Wei
 * @Date: 2022-03-29 12:12:05
 * @LastEditTime: 2023-07-07 10:43:19
 */

// exports.APS_URL = "https://aps.nacho.cn/"
// exports.PES_URL = "https://pes.nacho.cn/"
// exports.FMM_URL = "https://fmm.nacho.cn/"
// exports.PDM_URL = "https://pdm.nacho.cn/"
// exports.RMS_URL = "https://rms.nacho.cn/"

exports.APS_URL = "https://aps.stecip.com/";
exports.PES_URL = "https://pes.stecip.com/";
exports.FMM_URL = "https://fmm.stecip.com/";
exports.PDM_URL = "https://pdm.stecip.com/";
exports.RMS_URL = "https://rms.stecip.com/";

exports.LOCAL_URL = "http://127.0.0.1:8002"
