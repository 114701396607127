<template>
  <div>
    <!-- 构件信息 -->
    <div v-if="allDatas" class="con">
      <div class="cot hg">
        <div class="coh hr">
          <div class="coo">所属项目:</div>
          <div class="cop">
            {{ allDatas.projectName }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">骨架编码:</div>
          <div class="cop">
            {{ allDatas.serialNumber }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">骨架型号:</div>
          <div class="cop">
            {{ allDatas.cageName }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">焊&nbsp;&nbsp;接&nbsp;&nbsp;人:</div>
          <div class="cop">
            {{ allDatas.cageWeldUserName }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">打印时间:</div>
          <div class="cop">
            {{
              allDatas.printTime
                ? dayjs(allDatas.printTime).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态:</div>
          <div class="cop">
            {{ allDatas.status }}
          </div>
        </div>

        <div class="coh hr">
          <div class="coo">骨架上报时间:</div>
          <div class="cop">
            {{
              allDatas.cageReportTime
                ? dayjs(allDatas.cageReportTime).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { PES_URL } from "../../setting/server.seeting";
export default {
  created() {
    this.$axios({
      method: "get",
      url: `/api/pes-management/qr-code/CageQRCodeInfo`,
      params: { id: this.$route.params.id },
      baseURL: process.env.NODE_ENV === "development" ? "/pes" : PES_URL,
    })
      .then((res) => {
        this.allDatas = res.data;
      })
      .catch((err) => {
        console.log("数据获取错误!", err);
      });
  },
  data() {
    return {
      allDatas: {},
      dayjs,
    };
  },
};
</script>
<style scoped>
.con {
  padding: 10px;
}
.coh {
  width: 100%;
  height: 40px;
  /* line-height: 40px; */
  display: flex;
  align-items: center;
}
.cok {
  height: 38px;
  background: #004387;
  border: 1px solid #417ff9;
  border-radius: 5px 5px 0px 0px;
  color: #f3f3f3;
}
.cob {
  width: 100%;
  height: 100%;
  border-left: 1px solid #417ff9;
  border-right: 1px solid #417ff9;
  border-bottom: 1px solid #417ff9;
}
.coi {
  width: 28%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.coj {
  width: 65%;
  float: left;
  font-size: 14px;
}
.coo {
  min-width: 28%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.cop {
  width: 70%;
  float: left;
  font-size: 14px;

  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}
.coq {
  width: 20%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.cos {
  width: 15%;
  float: left;
  font-size: 14px;
  color: #666666;
  padding: 0 10px;
}
.cor {
  width: 25%;
  float: left;
  font-size: 14px;
}
.hg {
  border: 1px solid #417ff9;
  border-radius: 5px;
}
.hr {
  border-bottom: 1px solid rgb(190 211 253);
}
</style>
